<template>
  <div class="footer-builder">
    <!-- Show toolbar only if there are elements -->
    <div class="toolbar" v-if="canEditFooter">
      <div class="toolbar-left">
        <b-Button v-if="!previewMode" @click="addElement" variant="primary">
          <span class="plus-icon">+</span> Add Element
        </b-Button>
      </div>

      <div class="toolbar-right">
        <b-button @click="previewFooter" variant="info" v-if="!previewMode">
          Preview
        </b-button>
        <b-button @click="exitPreview" variant="danger" v-else>
          Exit Preview
        </b-button>
        <b-button v-if="!previewMode" @click="saveFooter" variant="success">
          Save
        </b-button>
      </div>
    </div>

    <div v-if="elements.length === 0 && !canEditFooter">&nbsp;</div>
    
    <!-- Empty state with centered button -->
    <div v-else-if="elements.length === 0 && canEditFooter" class="empty-state">
      <div class="empty-state-content">
        <feather-icon icon="Edit3Icon" size="48" class="empty-state-icon" />
        <h3 class="empty-state-title">Start Creating Your Footer</h3>
        <p class="empty-state-description">Click on add element to begin designing your custom footer</p>
        <!-- <b-button @click="addElement" variant="primary" class="empty-state-button">
          Start Editing Footer
        </b-button> -->
      </div>
    </div>

    <div v-else class="footer-container" ref="footerContainer" :style="containerStyle" :class="{
      'show-grid': snapToGrid && !previewMode,
      'preview-mode': previewMode || !canEditFooter,
      'edit-mode': !previewMode
    }">

      <!-- Elements -->
      <div v-for="(element, index) in elements" :key="index" class="footer-element" :class="{
        'active': activeElementIndex === index && !previewMode && canEditFooter,
        'dragging': isDragging && activeElementIndex === index && !previewMode && canEditFooter,
        'resizing': isResizing && activeElementIndex === index && !previewMode && canEditFooter,
        'collision-warning': element.showCollisionWarning && !previewMode,
        'boundary-warning': element.showBoundaryWarning && !previewMode,
        'preview-element': previewMode  || !canEditFooter
      }" :style="{
        left: `${element.x}px`,
        top: `${element.y}px`,
        width: `${element.width}px`,
        height: `${element.height}px`,
        zIndex: activeElementIndex === index && !previewMode ? 10 : 1
      }" @mousedown="!previewMode && canEditFooter && selectElement(index, $event)"
        @dblclick.stop="!previewMode && canEditFooter && openRichTextEditor(index, $event)">
        <!-- Delete icon (always visible except in preview) -->
        <button v-if="!previewMode && canEditFooter" class="delete-icon" @mousedown.stop @click.stop="removeElement(index)"
          title="Delete element">
          <span class="delete-icon-symbol">×</span>
        </button>

        <!-- Element content -->
        <div class="element-content" v-if="element.type === 'text'">
          <div v-if="!element.richContent" class="plain-text-content">
            {{ element.content || 'Double-click to edit' }}
          </div>
          <div v-else v-html="element.richContent" class="rich-text-content"></div>
        </div>

        <!-- Collision warning (hidden in preview) -->
        <div v-if="element.showCollisionWarning && !previewMode" class="warning-message collision">
          Cannot overlap with other elements
        </div>

        <!-- Boundary warning (hidden in preview) -->
        <div v-if="element.showBoundaryWarning && !previewMode" class="warning-message boundary">
          Must stay within container
        </div>

        <!-- Resize handles with tooltips (hidden in preview) -->
        <template v-if="activeElementIndex === index && !previewMode && canEditFooter">
          <div v-for="handle in resizeHandles" :key="handle.position"
            class="resize-handle" :class="handle.position" @mousedown.stop="startResize(index, handle.position, $event)"
            :title="handle.tooltip"></div>
        </template>



        <!-- Drag handle (hidden in preview) -->
        <div v-if="activeElementIndex === index && !previewMode && canEditFooter" class="drag-handle"
          @mousedown.stop="startDrag(index, $event)" title="Drag to move">
          <span class="drag-icon">⋮⋮</span>
        </div>

        <!-- Size indicator (hidden in preview) -->
        <div v-if="isResizing && activeElementIndex === index && !previewMode && canEditFooter" class="size-indicator">
          {{ Math.round(element.width) }} × {{ Math.round(element.height) }}
        </div>

        <!-- Position indicator (hidden in preview) -->
        <div v-if="isDragging && activeElementIndex === index && !previewMode && canEditFooter" class="position-indicator">
          {{ Math.round(element.x) }}, {{ Math.round(element.y) }}
        </div>
      </div>

      <!-- Guidelines for alignment (hidden in preview) -->
      <div v-if="showVerticalGuideline && !previewMode" class="guideline vertical"
        :style="{ left: `${guidelinePosition.x}px` }"></div>
      <div v-if="showHorizontalGuideline && !previewMode" class="guideline horizontal"
        :style="{ top: `${guidelinePosition.y}px` }"></div>
    </div>

    <!-- Quill Rich Text Editor Modal -->
    <div v-if="showRichTextEditor" class="modal-overlay" @click.stop="closeRichTextEditor">
      <div class="modal-content rich-text-modal" @click.stop>
        <h3>Edit Content</h3>

        <!-- The toolbar container -->
        <div ref="toolbar"></div>

        <ckeditor style="border: 1px solid #cecece;min-height: 200px;" :editor="editor" v-model="editorContent"
          :config="editorConfig" @ready="onEditorReady" />

        <div class="modal-buttons">
          <b-button @click.stop="saveRichText" variant="primary">Save</b-button>
          <b-button @click.stop="closeRichTextEditor" variant="secondary">Cancel</b-button>
        </div>
      </div>
    </div>

    <!-- Link modal -->
    <!-- Tooltip -->
    <div v-if="showTooltip" class="tooltip" :style="{ left: `${tooltipPosition.x}px`, top: `${tooltipPosition.y}px` }">
      {{ tooltipText }}
    </div>

    <!-- Save success notification -->
    <div v-if="showSaveNotification" class="save-notification">
      <div class="notification-content">
        <span class="notification-icon">✓</span>
        <span class="notification-text">Footer saved successfully</span>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import CKEditor from '@ckeditor/ckeditor5-vue2'

class CustomUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return new Promise((resolve) => {
      this.loader.file.then((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({
            default: reader.result
          });
        };
        reader.readAsDataURL(file);
      });
    });
  }

  abort() {
    // Abort upload implementation
  }
}

export default {
  name: 'FooterBuilder',

  components: {
    quillEditor,
    ckeditor: CKEditor.component
  },

  props: {
    initialState: {
      type: String,
      default: ''
    },
    handleChangedFooter: {
      type: Function,
      required: true
    },
    canEditFooter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      elements: [],
      activeElementIndex: null,
      isDragging: false,
      isResizing: false,
      resizeHandle: null,
      dragStartX: 0,
      dragStartY: 0,
      elementStartX: 0,
      elementStartY: 0,
      elementStartWidth: 0,
      elementStartHeight: 0,
      isEditing: false,

      // Preview mode
      previewMode: false,

      // Save notification
      showSaveNotification: false,

      // Image selector
      showImageSelector: false,
      tempImageUrl: '',
      imageElementIndex: null,

      // Video selector
      showVideoSelector: false,
      tempVideoUrl: '',
      videoElementIndex: null,

      // Rich text editor
      showRichTextEditor: false,
      richTextElementIndex: null,
      editorContent: '',
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            'bold',
            'italic',              // Headings (H1, H2, etc.)
            'underline',
            '|',
            'fontColor',           // Text color
            'fontSize',            // Text size
            'fontBackgroundColor',
            '|',
            'alignment',           // Text alignment
            'link',               // Links
            '|',
            'imageUpload',        // Image upload
            // 'mediaEmbed',              //video
            'blockQuote',         // Quotes
            'horizontalLine'      // Divider horizontal line
          ],
          shouldNotGroupWhenFull: true
        },
        heading: {
          options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' }
          ]
        },
        fontSize: {
          options: [
            'tiny',
            'small',
            'default',
            'big',
            'huge'
          ]
        },
        fontColor: {
          columns: 8,
          colors: [
            // Basic
            { color: '#000000', label: 'Black' },
            { color: '#FFFFFF', label: 'White' },
            { color: '#4B5563', label: 'Gray' },
            { color: '#9CA3AF', label: 'Light Gray' },
            
            // Blues
            { color: '#2563EB', label: 'Blue' },
            { color: '#1D4ED8', label: 'Dark Blue' },
            { color: '#3B82F6', label: 'Light Blue' },
            { color: '#60A5FA', label: 'Sky Blue' },
            { color: '#0EA5E9', label: 'Bright Blue' },
            
            // Greens
            { color: '#16A34A', label: 'Green' },
            { color: '#15803D', label: 'Dark Green' },
            { color: '#22C55E', label: 'Light Green' },
            { color: '#4ADE80', label: 'Lime Green' },
            { color: '#059669', label: 'Teal' },
            
            // Reds
            { color: '#DC2626', label: 'Red' },
            { color: '#B91C1C', label: 'Dark Red' },
            { color: '#EF4444', label: 'Light Red' },
            { color: '#F87171', label: 'Coral Red' },
            { color: '#FB7185', label: 'Rose' },
            
            // Yellows & Oranges
            { color: '#CA8A04', label: 'Yellow' },
            { color: '#EAB308', label: 'Light Yellow' },
            { color: '#D97706', label: 'Orange' },
            { color: '#EA580C', label: 'Dark Orange' },
            { color: '#FB923C', label: 'Light Orange' },
            
            // Purples & Pinks
            { color: '#7C3AED', label: 'Purple' },
            { color: '#6D28D9', label: 'Dark Purple' },
            { color: '#A855F7', label: 'Light Purple' },
            { color: '#DB2777', label: 'Pink' },
            { color: '#EC4899', label: 'Light Pink' },
            
            // Browns & Warm
            { color: '#92400E', label: 'Brown' },
            { color: '#B45309', label: 'Light Brown' },
            { color: '#78350F', label: 'Dark Brown' },
            { color: '#C2410C', label: 'Burnt Orange' },
            { color: '#A16207', label: 'Golden Brown' },
            
            // Cool Colors
            { color: '#0891B2', label: 'Cyan' },
            { color: '#0E7490', label: 'Dark Cyan' },
            { color: '#06B6D4', label: 'Light Cyan' },
            { color: '#0D9488', label: 'Dark Teal' },
            { color: '#14B8A6', label: 'Light Teal' }
          ]
        },
        link: {
          defaultProtocol: 'https://'
        },
        extraPlugins: [
          function (editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
              return new CustomUploadAdapter(loader);
            };

            // Add custom handler for image insertion
            editor.model.document.on('change:data', () => {
              const images = editor.editing.view.domRoots.get('main').querySelectorAll('img');
              images.forEach(img => {
                // Make image fill the element
                img.style.width = '100%';
                img.style.height = '100%';
                img.style.objectFit = 'contain';
                img.style.display = 'block';
              });
            });
          }
        ]
      },

      // Link modal
      // Enhanced features
      snapToGrid: false,
      gridSize: 20,
      lockAspectRatio: false,
      aspectRatio: 1,
      showTooltip: false,
      tooltipText: '',
      tooltipPosition: { x: 0, y: 0 },
      showVerticalGuideline: false,
      showHorizontalGuideline: false,
      guidelinePosition: { x: 0, y: 0 },

      // Boundary and collision indicators
      showBoundaryIndicator: false,
      boundaryDirection: '',

      // Resize handles configuration
      resizeHandles: [
        { position: 'top-left', tooltip: 'Resize from top-left' },
        { position: 'top', tooltip: 'Resize from top' },
        { position: 'top-right', tooltip: 'Resize from top-right' },
        { position: 'right', tooltip: 'Resize from right' },
        { position: 'bottom-right', tooltip: 'Resize from bottom-right' },
        { position: 'bottom', tooltip: 'Resize from bottom' },
        { position: 'bottom-left', tooltip: 'Resize from bottom-left' },
        { position: 'left', tooltip: 'Resize from left' }
      ]
    };
  },

  mounted() {
    if (this.initialState) {
      try {
        // Check if the initialState contains HTML-like content
        if (this.initialState.trim().startsWith('<')) {
          console.error('Invalid JSON format: initialState contains HTML content');
          // this.addElement();
          return;
        }

        const parsedState = JSON.parse(this.initialState);
        parsedState?.elements?.length > 0 && this.loadFooterState(parsedState);
      } catch (error) {
        console.error('Error parsing initial state:', error);
        // this.addElement();
      }
    }

    // Add event listener for tooltip
    document.addEventListener('mousemove', this.updateTooltipPosition);

    // Add window resize listener to ensure elements stay within container
    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeDestroy() {
    // Clean up event listeners
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    document.removeEventListener('mousemove', this.updateTooltipPosition);
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    onEditorReady(editor) {
      const toolbarContainer = this.$refs.toolbar;
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);

      // Add custom CSS to editor content
      const editorElement = editor.ui.getEditableElement();
      if (editorElement) {
        const style = document.createElement('style');
        style.textContent = `
          figure.image {
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
            display: block !important;
          }
          figure.image img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover !important;
            display: block !important;
          }
        `;
        editorElement.appendChild(style);
      }
    },

    // Preview the footer
    previewFooter() {
      this.previewMode = true;
      this.activeElementIndex = null;
    },

    // Exit preview mode
    exitPreview() {
      this.previewMode = false;
    },

    escapeQuotes(str) {
      return str.replace(/"/g, '\\"');
    },

    // Save footer state to console
    saveFooter() {
      // Create a clean version of the elements without internal state
      const cleanElements = this.elements.map(element => {
        const cleanElement = {
          type: element.type,
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height
        };
        if (element.type === 'text') {
          cleanElement.content = element.content || '';
          cleanElement.richContent = element.richContent
        } else if (element.type === 'image') {
          cleanElement.imageUrl = element.imageUrl || '';
        } else if (element.type === 'video') {
          cleanElement.videoUrl = element.videoUrl || '';
        }

        return cleanElement;
      });

      // Create the footer state object
      const footerState = {
        elements: cleanElements,
        settings: {
          snapToGrid: this.snapToGrid,
          gridSize: this.gridSize,
          lockAspectRatio: this.lockAspectRatio
        },
        containerSize: {
          width: this.$refs.footerContainer ? this.$refs.footerContainer.clientWidth : 0,
          height: this.$refs.footerContainer ? this.$refs.footerContainer.clientHeight : 0
        },
        version: '1.0'
      };

      // Convert to string and pass to parent component
      const footerStateString = JSON.stringify(footerState);
      this.handleChangedFooter(footerStateString);

      // Show save notification
      this.showSaveNotification = true;
      setTimeout(() => {
        this.showSaveNotification = false;
        this.previewMode = true;
      }, 3000);
    },

    // Load footer state from saved data
    loadFooterState(state) {
      if (!state || !state.elements || !Array.isArray(state.elements)) {
        console.error('Invalid state format:', state);
        this.addElement(); // Add default element
        return;
      }

      // Load settings
      if (state.settings) {
        this.snapToGrid = state.settings.snapToGrid || false;
        this.gridSize = state.settings.gridSize || 20;
        this.lockAspectRatio = state.settings.lockAspectRatio || false;
      }

      // Load elements
      this.elements = state.elements.map(element => {
        // Create a new element with all required properties
        const newElement = {
          type: element.type || 'text',
          x: element.x || 0,
          y: element.y || 0,
          width: element.width || 200,
          height: element.height || 100,
          showCollisionWarning: false,
          showBoundaryWarning: false
        };

        // Add content based on type
        if (element.type === 'text') {
          newElement.content = element.content || '';
          newElement.richContent = element.richContent || '';
        } else if (element.type === 'image') {
          newElement.imageUrl = element.imageUrl || '';
        } else if (element.type === 'video') {
          newElement.videoUrl = element.videoUrl || '';
        }

        return newElement;
      });

     
    },

    // Handle window resize to ensure elements stay within container
    handleWindowResize() {
      if (!this.$refs.footerContainer) return;

      const containerWidth = this.$refs.footerContainer.clientWidth;
      const containerHeight = this.$refs.footerContainer.clientHeight;

      // Check each element and adjust if needed
      this.elements.forEach((element, index) => {
        let needsUpdate = false;
        let newX = element.x;
        let newY = element.y;
        let newWidth = element.width;
        let newHeight = element.height;

        // Check right boundary
        if (element.x + element.width > containerWidth) {
          if (element.width > containerWidth) {
            // Element is wider than container, resize it
            newWidth = containerWidth - 10;
            newX = 5;
          } else {
            // Just move it inside
            newX = containerWidth - element.width;
          }
          needsUpdate = true;
        }

        // Check bottom boundary
        if (element.y + element.height > containerHeight) {
          if (element.height > containerHeight) {
            // Element is taller than container, resize it
            newHeight = containerHeight - 10;
            newY = 5;
          } else {
            // Just move it inside
            newY = containerHeight - element.height;
          }
          needsUpdate = true;
        }

        // Update element if needed
        if (needsUpdate) {
          this.$set(element, 'x', newX);
          this.$set(element, 'y', newY);
          this.$set(element, 'width', newWidth);
          this.$set(element, 'height', newHeight);
        }
      });
    },

    // Add a new element to the footer
    addElement() {
      const newElement = {
        type: 'text',
        content: '',
        richContent: '',
        x: 20,
        y: 20,
        width: 200,
        height: 100,
        imageUrl: null,
        videoUrl: null,
        showCollisionWarning: false,
        showBoundaryWarning: false
      };

      // Check for position that doesn't overlap
      this.positionNewElement(newElement);

      this.elements.push(newElement);
      this.activeElementIndex = this.elements.length - 1;

      // If this is the first element, exit preview mode
      if (this.elements.length === 1) {
        this.previewMode = false;
      }
    },

    // Find a position for new element that doesn't overlap
    positionNewElement(newElement) {
      let attempts = 0;
      let foundPosition = false;

      // Get container dimensions
      const containerWidth = this.$refs.footerContainer ? this.$refs.footerContainer.clientWidth : 800;
      const containerHeight = this.$refs.footerContainer ? this.$refs.footerContainer.clientHeight : 300;

      while (!foundPosition && attempts < 20) {
        foundPosition = true;

        // Check against all existing elements
        for (const element of this.elements) {
          if (
            newElement.x < element.x + element.width &&
            newElement.x + newElement.width > element.x &&
            newElement.y < element.y + element.height &&
            newElement.y + newElement.height > element.y
          ) {
            // Overlap detected, try a new position
            foundPosition = false;
            newElement.x += 30;
            newElement.y += 30;

            // If we're going too far right, reset X and increase Y
            if (newElement.x + newElement.width > containerWidth - 20) {
              newElement.x = 20;
              newElement.y += 50;
            }

            // If we're going too far down, reset both
            if (newElement.y + newElement.height > containerHeight - 20) {
              newElement.x = 20;
              newElement.y = 20;

              // If we've tried everything, just make it smaller
              if (attempts > 15) {
                newElement.width = Math.min(newElement.width, 150);
                newElement.height = Math.min(newElement.height, 80);
              }
            }

            break;
          }
        }

        // Check container boundaries
        if (newElement.x < 0) newElement.x = 0;
        if (newElement.y < 0) newElement.y = 0;
        if (newElement.x + newElement.width > containerWidth) {
          if (newElement.width > containerWidth - 40) {
            newElement.width = containerWidth - 40;
          }
          newElement.x = containerWidth - newElement.width - 20;
        }
        if (newElement.y + newElement.height > containerHeight) {
          if (newElement.height > containerHeight - 40) {
            newElement.height = containerHeight - 40;
          }
          newElement.y = containerHeight - newElement.height - 20;
        }

        attempts++;
      }

      // Apply snap to grid if enabled
      if (this.snapToGrid) {
        newElement.x = Math.round(newElement.x / this.gridSize) * this.gridSize;
        newElement.y = Math.round(newElement.y / this.gridSize) * this.gridSize;
        newElement.width = Math.round(newElement.width / this.gridSize) * this.gridSize;
        newElement.height = Math.round(newElement.height / this.gridSize) * this.gridSize;
      }
    },

    // Select an element
    selectElement(index, event) {
      if (this.previewMode) return;

      event.preventDefault();
      this.activeElementIndex = index;

      // If the element is already selected, start dragging
      if (this.activeElementIndex === index) {
        this.startDrag(index, event);
      }
    },

    // Start dragging an element
    startDrag(index, event) {
      if (this.previewMode) return;

      event.preventDefault();
      this.isDragging = true;

      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.elementStartX = this.elements[index].x;
      this.elementStartY = this.elements[index].y;

      // Calculate aspect ratio if needed
      if (this.lockAspectRatio) {
        const element = this.elements[index];
        this.aspectRatio = element.width / element.height;
      }

      // Add event listeners for drag
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);

      // Clear any warning flags
      this.clearWarnings(index);
    },

    // Start resizing an element
    startResize(index, handle, event) {
      if (this.previewMode) return;

      event.preventDefault();
      this.activeElementIndex = index;
      this.isResizing = true;
      this.resizeHandle = handle;

      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      this.elementStartX = this.elements[index].x;
      this.elementStartY = this.elements[index].y;
      this.elementStartWidth = this.elements[index].width;
      this.elementStartHeight = this.elements[index].height;

      // Calculate aspect ratio if needed
      if (this.lockAspectRatio) {
        this.aspectRatio = this.elementStartWidth / this.elementStartHeight;
      }

      // Add event listeners for resize
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);

      // Clear any warning flags
      this.clearWarnings(index);
    },

    // Clear warning flags
    clearWarnings(index) {
      const element = this.elements[index];
      if (element.showCollisionWarning) {
        this.$set(element, 'showCollisionWarning', false);
      }
      if (element.showBoundaryWarning) {
        this.$set(element, 'showBoundaryWarning', false);
      }
      this.showBoundaryIndicator = false;
    },

    // Handle mouse movement for drag and resize
    handleMouseMove(event) {
      if (this.previewMode || (!this.isDragging && !this.isResizing)) return;

      const deltaX = event.clientX - this.dragStartX;
      const deltaY = event.clientY - this.dragStartY;
      const element = this.elements[this.activeElementIndex];

      // Get container dimensions
      const containerWidth = this.$refs.footerContainer.clientWidth;
      const containerHeight = this.$refs.footerContainer.clientHeight;

      if (this.isDragging) {
        // Calculate new position
        let newX = this.elementStartX + deltaX;
        let newY = this.elementStartY + deltaY;

        // Apply snap to grid if enabled
        if (this.snapToGrid) {
          newX = Math.round(newX / this.gridSize) * this.gridSize;
          newY = Math.round(newY / this.gridSize) * this.gridSize;
        }

        // Check for alignment with other elements (show guidelines)
        this.checkAlignment(newX, newY, element.width, element.height);

        // Check boundary constraints
        let boundaryViolation = false;
        this.boundaryDirection = '';

        if (newX < 0) {
          newX = 0;
          boundaryViolation = true;
          this.boundaryDirection = 'left';
        }

        if (newY < 0) {
          newY = 0;
          boundaryViolation = true;
          this.boundaryDirection = 'top';
        }

        if (newX + element.width > containerWidth) {
          newX = containerWidth - element.width;
          boundaryViolation = true;
          this.boundaryDirection = 'right';
        }

        if (newY + element.height > containerHeight) {
          newY = containerHeight - element.height;
          boundaryViolation = true;
          this.boundaryDirection = 'bottom';
        }

        // Show boundary indicator if needed
        this.showBoundaryIndicator = boundaryViolation;

        // Set boundary warning on element
        if (boundaryViolation !== element.showBoundaryWarning) {
          this.$set(element, 'showBoundaryWarning', boundaryViolation);
        }

        // Check for collisions with other elements
        const wouldCollide = this.checkCollision(
          this.activeElementIndex,
          newX,
          newY,
          element.width,
          element.height
        );

        // Set collision warning on element
        if (wouldCollide !== element.showCollisionWarning) {
          this.$set(element, 'showCollisionWarning', wouldCollide);
        }

        if (!wouldCollide) {
          // Use Vue.set to ensure reactivity in Vue 2
          this.$set(element, 'x', newX);
          this.$set(element, 'y', newY);
        }
      } else if (this.isResizing) {
        let newX = element.x;
        let newY = element.y;
        let newWidth = element.width;
        let newHeight = element.height;

        // Handle different resize handles
        switch (this.resizeHandle) {
          case 'bottom-right':
            newWidth = this.elementStartWidth + deltaX;
            newHeight = this.elementStartHeight + deltaY;
            break;
          case 'bottom-left':
            newX = this.elementStartX + deltaX;
            newWidth = this.elementStartWidth - deltaX;
            newHeight = this.elementStartHeight + deltaY;
            break;
          case 'top-right':
            newY = this.elementStartY + deltaY;
            newWidth = this.elementStartWidth + deltaX;
            newHeight = this.elementStartHeight - deltaY;
            break;
          case 'top-left':
            newX = this.elementStartX + deltaX;
            newY = this.elementStartY + deltaY;
            newWidth = this.elementStartWidth - deltaX;
            newHeight = this.elementStartHeight - deltaY;
            break;
          case 'top':
            newY = this.elementStartY + deltaY;
            newHeight = this.elementStartHeight - deltaY;
            break;
          case 'right':
            newWidth = this.elementStartWidth + deltaX;
            break;
          case 'bottom':
            newHeight = this.elementStartHeight + deltaY;
            break;
          case 'left':
            newX = this.elementStartX + deltaX;
            newWidth = this.elementStartWidth - deltaX;
            break;
        }

        // Apply aspect ratio lock if enabled
        if (this.lockAspectRatio) {
          // Determine which dimension to prioritize based on the handle
          if (['right', 'left'].includes(this.resizeHandle)) {
            // Prioritize width
            newHeight = newWidth / this.aspectRatio;
          } else if (['top', 'bottom'].includes(this.resizeHandle)) {
            // Prioritize height
            newWidth = newHeight * this.aspectRatio;
          } else {
            // For corner handles, use the larger delta
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
              newHeight = newWidth / this.aspectRatio;
            } else {
              newWidth = newHeight * this.aspectRatio;
            }
          }
        }

        // Apply snap to grid if enabled
        if (this.snapToGrid) {
          newX = Math.round(newX / this.gridSize) * this.gridSize;
          newY = Math.round(newY / this.gridSize) * this.gridSize;
          newWidth = Math.round(newWidth / this.gridSize) * this.gridSize;
          newHeight = Math.round(newHeight / this.gridSize) * this.gridSize;
        }

        // Enforce minimum size
        newWidth = Math.max(50, newWidth);
        newHeight = Math.max(50, newHeight);

        // Check boundary constraints
        let boundaryViolation = false;
        this.boundaryDirection = '';

        if (newX < 0) {
          if (['top-left', 'bottom-left', 'left'].includes(this.resizeHandle)) {
            // Adjust width instead of position for left-side handles
            newWidth = this.elementStartWidth + this.elementStartX;
            newX = 0;
          } else {
            newX = 0;
          }
          boundaryViolation = true;
          this.boundaryDirection = 'left';
        }

        if (newY < 0) {
          if (['top-left', 'top-right', 'top'].includes(this.resizeHandle)) {
            // Adjust height instead of position for top handles
            newHeight = this.elementStartHeight + this.elementStartY;
            newY = 0;
          } else {
            newY = 0;
          }
          boundaryViolation = true;
          this.boundaryDirection = 'top';
        }

        if (newX + newWidth > containerWidth) {
          if (['top-right', 'bottom-right', 'right'].includes(this.resizeHandle)) {
            // Adjust width to fit container
            newWidth = containerWidth - newX;
          } else {
            // For other handles, ensure element stays within container
            newX = containerWidth - newWidth;
          }
          boundaryViolation = true;
          this.boundaryDirection = 'right';
        }

        if (newY + newHeight > containerHeight) {
          if (['bottom-left', 'bottom-right', 'bottom'].includes(this.resizeHandle)) {
            // Adjust height to fit container
            newHeight = containerHeight - newY;
          } else {
            // For other handles, ensure element stays within container
            newY = containerHeight - newHeight;
          }
          boundaryViolation = true;
          this.boundaryDirection = 'bottom';
        }

        // Show boundary indicator if needed
        this.showBoundaryIndicator = boundaryViolation;

        // Set boundary warning on element
        if (boundaryViolation !== element.showBoundaryWarning) {
          this.$set(element, 'showBoundaryWarning', boundaryViolation);
        }

        // Check for collisions with other elements
        const wouldCollide = this.checkCollision(
          this.activeElementIndex,
          newX,
          newY,
          newWidth,
          newHeight
        );

        // Set collision warning on element
        if (wouldCollide !== element.showCollisionWarning) {
          this.$set(element, 'showCollisionWarning', wouldCollide);
        }

        if (!wouldCollide) {
          // Use Vue.set to ensure reactivity in Vue 2
          this.$set(element, 'x', newX);
          this.$set(element, 'y', newY);
          this.$set(element, 'width', newWidth);
          this.$set(element, 'height', newHeight);
        }
      }
    },

    // Check for alignment with other elements and show guidelines
    checkAlignment(x, y, width, height) {
      const threshold = 5; // Snap threshold in pixels
      const currentElement = { x, y, width, height };

      // Reset guidelines
      this.showVerticalGuideline = false;
      this.showHorizontalGuideline = false;

      for (let i = 0; i < this.elements.length; i++) {
        if (i === this.activeElementIndex) continue;

        const otherElement = this.elements[i];

        // Check for vertical alignment (left edges, centers, right edges)
        if (Math.abs(x - otherElement.x) < threshold) {
          // Left edges align
          this.showVerticalGuideline = true;
          this.guidelinePosition.x = otherElement.x;
          break;
        } else if (Math.abs((x + width) - (otherElement.x + otherElement.width)) < threshold) {
          // Right edges align
          this.showVerticalGuideline = true;
          this.guidelinePosition.x = otherElement.x + otherElement.width;
          break;
        } else if (Math.abs((x + width / 2) - (otherElement.x + otherElement.width / 2)) < threshold) {
          // Centers align
          this.showVerticalGuideline = true;
          this.guidelinePosition.x = otherElement.x + otherElement.width / 2;
          break;
        }
      }

      for (let i = 0; i < this.elements.length; i++) {
        if (i === this.activeElementIndex) continue;

        const otherElement = this.elements[i];

        // Check for horizontal alignment (top edges, centers, bottom edges)
        if (Math.abs(y - otherElement.y) < threshold) {
          // Top edges align
          this.showHorizontalGuideline = true;
          this.guidelinePosition.y = otherElement.y;
          break;
        } else if (Math.abs((y + height) - (otherElement.y + otherElement.height)) < threshold) {
          // Bottom edges align
          this.showHorizontalGuideline = true;
          this.guidelinePosition.y = otherElement.y + otherElement.height;
          break;
        } else if (Math.abs((y + height / 2) - (otherElement.y + otherElement.height / 2)) < threshold) {
          // Centers align
          this.showHorizontalGuideline = true;
          this.guidelinePosition.y = otherElement.y + otherElement.height / 2;
          break;
        }
      }
    },

    // Check if an element would collide with others
    checkCollision(currentIndex, x, y, width, height) {
      for (let i = 0; i < this.elements.length; i++) {
        if (i === currentIndex) continue;

        const other = this.elements[i];

        if (
          x < other.x + other.width &&
          x + width > other.x &&
          y < other.y + other.height &&
          y + height > other.y
        ) {
          return true; // Collision detected
        }
      }

      return false; // No collision
    },

    // Handle mouse up to end drag/resize
    handleMouseUp() {
      const element = this.elements[this.activeElementIndex];

      // Apply bounce-back animation if needed
      if (element && (element.showCollisionWarning || element.showBoundaryWarning)) {
        // Add animation class
        element.animating = true;

        // Clear warnings after animation
        setTimeout(() => {
          if (element) {
            this.$set(element, 'showCollisionWarning', false);
            this.$set(element, 'showBoundaryWarning', false);
            element.animating = false;
          }
        }, 300);
      }

      this.isDragging = false;
      this.isResizing = false;

      // Hide guidelines and boundary indicators
      this.showVerticalGuideline = false;
      this.showHorizontalGuideline = false;

      this.showBoundaryIndicator = false;

      // Remove event listeners
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    },

    // Open rich text editor
    openRichTextEditor(index, event) {
      if (this.previewMode) return;

      event.preventDefault();
      event.stopPropagation();

      this.richTextElementIndex = index;
      this.showRichTextEditor = true;

      const element = this.elements[index];

      // Initialize editor content
      this.$nextTick(() => {
        if (element.richContent) {
          this.editorContent = element.richContent;
        } else if (element.content) {
          this.editorContent = element.content;
        } else {
          this.editorContent = '';
        }
      });
    },

    // Close rich text editor
    closeRichTextEditor() {
      this.showRichTextEditor = false;
      this.richTextElementIndex = null;
      this.editorContent = '';
    },

    // Save rich text content
    saveRichText() {
      if (this.richTextElementIndex !== null) {
        const element = this.elements[this.richTextElementIndex];
        let content = this.editorContent;

        // Process images in content
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = content;
        
        // Adjust all images in the content
        const images = tempDiv.getElementsByTagName('img');
        Array.from(images).forEach(img => {
          img.classList.add('full-size-image');
        });

        content = tempDiv.innerHTML;

        // Update the element
        this.$set(element, 'richContent', content);
        const textContent = tempDiv.textContent || tempDiv.innerText || '';
        this.$set(element, 'content', textContent);

        // Create and save footer state
        this.closeRichTextEditor();
      }
    },

    // Change element type
    changeElementType(index, type) {
      const element = this.elements[index];

      if (element.type === type) return;

      this.$set(element, 'type', type);

      if (type === 'image' && !element.imageUrl) {
        this.openImageSelector(index);
      } else if (type === 'video' && !element.videoUrl) {
        this.openVideoSelector(index);
      }
    },

    // Open image selector modal
    openImageSelector(index) {
      this.imageElementIndex = index;
      this.tempImageUrl = this.elements[index].imageUrl || '';
      this.showImageSelector = true;
    },

    // Confirm image URL
    confirmImageUrl() {
      if (this.imageElementIndex !== null) {
        this.$set(this.elements[this.imageElementIndex], 'imageUrl', this.tempImageUrl);
        this.showImageSelector = false;
      }
    },

    // Cancel image selection
    cancelImageSelection() {
      this.showImageSelector = false;
    },

    // Open video selector modal
    openVideoSelector(index) {
      this.videoElementIndex = index;
      this.tempVideoUrl = this.elements[index].videoUrl || '';
      this.showVideoSelector = true;
    },

    // Confirm video URL
    confirmVideoUrl() {
      if (this.videoElementIndex !== null) {
        this.$set(this.elements[this.videoElementIndex], 'videoUrl', this.tempVideoUrl);
        this.showVideoSelector = false;
      }
    },

    // Cancel video selection
    cancelVideoSelection() {
      this.showVideoSelector = false;
    },

    // Duplicate an element
    duplicateElement(index) {
      const original = this.elements[index];
      const clone = JSON.parse(JSON.stringify(original));

      // Offset the position slightly
      clone.x += 20;
      clone.y += 20;

      // Check for position that doesn't overlap
      this.positionNewElement(clone);

      this.elements.push(clone);
      this.activeElementIndex = this.elements.length - 1;
    },

    // Remove an element
    removeElement(index) {
      this.elements.splice(index, 1);
      this.activeElementIndex = null;
    },

    // Update tooltip position
    updateTooltipPosition(event) {
      if (this.showTooltip) {
        this.tooltipPosition = {
          x: event.clientX + 10,
          y: event.clientY + 10
        };
      }
    },

    // Show tooltip
    showElementTooltip(text) {
      this.tooltipText = text;
      this.showTooltip = true;
    },

    // Hide tooltip
    hideElementTooltip() {
      this.showTooltip = false;
    },

    // Optional: Add method to handle upload errors
    handleUploadError(error) {
      console.error('Image upload failed:', error);
      // Show error notification to user
      this.$bvToast.toast('Image upload failed. Please try again.', {
        title: 'Error',
        variant: 'danger',
        solid: true
      });
    }
  },

  computed: {
    containerStyle() {
      if (!this.canEditFooter && !this.previewMode) {
        // In preview mode, calculate height based on elements
        if (this.elements.length === 0) return { height: 'auto' };
        
        const maxBottom = Math.max(...this.elements.map(el => el.y + el.height));
        return { height: `${maxBottom}px` };
      }
      
      // In edit mode, use fixed height range
      const maxBottom = Math.max(...this.elements.map(el => el.y + el.height), 0);
      const calculatedHeight = Math.max(350, maxBottom + 50);
      // Limit maximum height to 600px in edit mode
      return { 
        height: `${Math.min(600, calculatedHeight)}px`,
        overflowY: calculatedHeight > 600 ? 'auto' : 'visible'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-builder {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Arial, sans-serif;
}

.toolbar {
  padding: 10px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar-left,
.toolbar-right {
  display: flex;
  align-items: center;
  gap: 15px;
}

.toolbar-options {
  display: flex;
  gap: 15px;
  margin-left: 15px;
}

.option {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  user-select: none;
}

.option-label {
  font-size: 14px;
}

.add-button,
.preview-button,
.exit-preview-button,
.save-button {
  display: flex;
  align-items: center;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}

.add-button {
  background-color: #4CAF50;
  color: white;
}

.add-button:hover {
  background-color: #45a049;
}

.preview-button {
  background-color: #2196F3;
  color: white;
}

.preview-button:hover {
  background-color: #0b7dda;
}

.exit-preview-button {
  background-color: #ff9800;
  color: white;
}

.exit-preview-button:hover {
  background-color: #e68a00;
}

.save-button {
  background-color: #673AB7;
  color: white;
}

.save-button:hover {
  background-color: #5e35b1;
}

.plus-icon,
.preview-icon,
.exit-icon,
.save-icon {
  margin-right: 5px;
  font-size: 16px;
}

.footer-container {
  position: relative;
  width: 100%;
  background: #f8fafc;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  margin: 20px 0;
  padding: 0;
  overflow: visible;
  transition: height 0.3s ease;

  &.preview-mode {
    border: none;
    background: transparent;
    min-height: auto;
    overflow: visible;
  }

  &.edit-mode {
    overflow-x: visible;
    &::-webkit-scrollbar {
      width: 8px;
    }
    
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 4px;
    }
    
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
      
      &:hover {
        background: #666;
      }
    }
  }

  .footer-element {
    position: absolute;
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    cursor: move;
    user-select: none;
    transition: border-color 0.2s ease;
    
    &.preview-element {
      border: none;
      background: transparent;
      cursor: default;
    }
  }
}

.footer-container.show-grid {
  background-image:
    linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}

.footer-container.preview-mode {
  background-color: white;
  min-height: auto;
}

.grid-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.grid-line {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
}

.grid-line.vertical {
  width: 1px;
  height: 100%;
  top: 0;
}

.grid-line.horizontal {
  height: 1px;
  width: 100%;
  left: 0;
}

.footer-element {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: move;
  overflow: hidden;
  border-radius: 4px;
  transition: box-shadow 0.2s, transform 0.1s;

  ::v-deep {
    .full-size-image {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain !important;
      display: block !important;
    }

    figure.image {
      width: 100% !important;
      height: 100% !important;
      margin: 0 !important;
      display: block !important;
      
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
        display: block !important;
      }
    }

    // Ensure the content container takes full height
    .element-content {
      height: 100%;
      
      .rich-text-content {
        height: 100%;
      }
    }
  }
}

.footer-element.preview-element {
  cursor: default;
  border: none;
  box-shadow: none;
}

.footer-element.active {
  border: 1px solid #2196F3;
  box-shadow: 0 0 8px rgba(33, 150, 243, 0.4);
}

.footer-element.dragging {
  opacity: 0.8;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.footer-element.resizing {
  opacity: 0.9;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.footer-element.collision-warning {
  border: 2px solid #ff5252;
  box-shadow: 0 0 10px rgba(255, 82, 82, 0.5);
  animation: shake 0.2s ease-in-out;
}

.footer-element.boundary-warning {
  border: 2px solid #ff9800;
  box-shadow: 0 0 10px rgba(255, 152, 0, 0.5);
  animation: pulse 0.3s ease-in-out;
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  75% {
    transform: translateX(5px);
  }
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.98);
  }
}

.element-content {
  width: 100%;
  height: 100%;
  padding: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
}

.rich-text-content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
}

.plain-text-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  background: transparent;
}

.element-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #666;
  cursor: pointer;
}

.video-frame {
  width: 100%;
  height: 100%;
  border: none;
}

.video-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  color: #666;
  cursor: pointer;
}

.warning-message {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 100;
  pointer-events: none;
}

.warning-message.collision {
  background-color: rgba(255, 82, 82, 0.9);
}

.warning-message.boundary {
  background-color: rgba(255, 152, 0, 0.9);
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #2196F3;
  border-radius: 50%;
  z-index: 2;
}

.top-left {
  top: -5px;
  left: -5px;
  cursor: nwse-resize;
}

.top {
  top: -5px;
  left: calc(50% - 5px);
  cursor: ns-resize;
}

.top-right {
  top: -5px;
  right: -5px;
  cursor: nesw-resize;
}

.right {
  top: calc(50% - 5px);
  right: -5px;
  cursor: ew-resize;
}

.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

.bottom {
  bottom: -5px;
  left: calc(50% - 5px);
  cursor: ns-resize;
}

.bottom-left {
  bottom: -5px;
  left: -5px;
  cursor: nesw-resize;
}

.left {
  top: calc(50% - 5px);
  left: -5px;
  cursor: ew-resize;
}

.element-controls {
  position: absolute;
  top: -30px;
  right: 0;
  display: flex;
  gap: 5px;
  z-index: 3;
}

.control-button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 2px 6px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  transition: background-color 0.2s;
}

.control-button:hover {
  background-color: #e0e0e0;
}

.control-button.active {
  background-color: #2196F3;
  color: white;
}

.control-icon {
  font-size: 14px;
}

.control-button.remove {
  background-color: #ff5252;
  color: white;
}

.control-button.remove:hover {
  background-color: #ff3232;
}

.control-button.duplicate {
  background-color: #4dabf7;
  color: white;
}

.control-button.duplicate:hover {
  background-color: #339af0;
}

.delete-icon {
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: #ff5252;
  color: white;
  border: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 1;
  z-index: 5;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.delete-icon:hover {
  opacity: 1;
}

.delete-icon-symbol {
  font-size: 14px;
  line-height: 1;
}

.drag-handle {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(33, 150, 243, 0.8);
  color: white;
  padding: 2px 4px;
  font-size: 12px;
  cursor: move;
  border-radius: 0 0 4px 0;
  z-index: 3;
}

.drag-icon {
  font-size: 10px;
  line-height: 1;
}

.size-indicator {
  position: absolute;
  bottom: -25px;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 100;
}

.position-indicator {
  position: absolute;
  top: -25px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 100;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
}

.rich-text-modal {
  width: 800px;
  max-width: 90%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  :deep(.ck-editor) {
    margin: 20px 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }

  :deep(.ck-toolbar) {
    background: #f8f9fa !important;
    border: 1px solid #e2e8f0 !important;
    border-bottom: none !important;
    border-radius: 8px 8px 0 0 !important;
    padding: 8px !important;

    .ck-toolbar__items {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
    }

    .ck-button {
      border-radius: 4px !important;
      padding: 6px 10px !important;
      transition: all 0.2s ease;
      background: white !important;
      border: 1px solid #e2e8f0 !important;

      &:hover {
        background: #edf2f7 !important;
        border-color: #cbd5e0 !important;
      }

      &.ck-on {
        background: #e2e8f0 !important;
        border-color: #cbd5e0 !important;
      }

      .ck-button__label {
        color: #4a5568;
      }

      .ck-icon {
        color: #4a5568;
      }
    }

    .ck-dropdown {
      .ck-button {
        padding-right: 24px !important;
      }

      .ck-dropdown__panel {
        border: 1px solid #e2e8f0 !important;
        border-radius: 4px !important;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
      }
    }

    .ck-toolbar__separator {
      background: #e2e8f0 !important;
      margin: 0 8px !important;
    }
  }

  :deep(.ck-editor__editable) {
    min-height: 300px !important;
    max-height: 500px !important;
    border: 1px solid #0050b9 !important;
    border-top: none !important;
    border-radius: 0 0 8px 8px !important;
    padding: 16px !important;
    background: white !important;
    color: #2d3748 !important;
    font-size: 1rem !important;
    line-height: 1.6 !important;

    &:focus {
      border-color: #4299e1 !important;
      box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2) !important;
    }

    p {
      margin-bottom: 1em;
    }

    h1,
    h2,
    h3,
    h4 {
      margin: 1em 0 0.5em;
      font-weight: 600;
    }
  }
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;

  .modal-button {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
    border: none;
    font-size: 0.95rem;

    &.confirm {
      background-color: #4299e1;
      color: white;

      &:hover {
        background-color: #3182ce;
      }
    }

    &.cancel {
      background-color: #e2e8f0;
      color: #4a5568;

      &:hover {
        background-color: #cbd5e0;
      }
    }
  }
}

/* Tooltip styles */
.tooltip {
  position: absolute;
  background: #2d3748;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  z-index: 1000;
  pointer-events: none;
}

.guideline {
  position: absolute;
  background-color: rgba(33, 150, 243, 0.5);
  pointer-events: none;
  z-index: 5;
}

.guideline.vertical {
  width: 1px;
  height: 100%;
  top: 0;
}

.guideline.horizontal {
  height: 1px;
  width: 100%;
  left: 0;
}

.boundary-indicator {
  position: absolute;
  background-color: rgba(255, 152, 0, 0.3);
  pointer-events: none;
  z-index: 4;
}

.boundary-indicator.left {
  width: 5px;
  height: 100%;
  left: 0;
  top: 0;
}

.boundary-indicator.right {
  width: 5px;
  height: 100%;
  right: 0;
  top: 0;
}

.boundary-indicator.top {
  height: 5px;
  width: 100%;
  top: 0;
  left: 0;
}

.boundary-indicator.bottom {
  height: 5px;
  width: 100%;
  bottom: 0;
  left: 0;
}

.save-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(76, 175, 80, 0.9);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 2000;
  animation: fadeInOut 3s ease-in-out;
}

.notification-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-icon {
  font-size: 18px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  10% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  background: #f8fafc;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  margin: 20px 0;
  padding: 40px;

  .empty-state-content {
    text-align: center;
    max-width: 400px;
  }

  .empty-state-icon {
    color: #94a3b8;
    margin-bottom: 20px;
  }

  .empty-state-title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin-bottom: 12px;
  }

  .empty-state-description {
    color: #64748b;
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 24px;
  }

  .empty-state-button {
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 500;
    background-color: #3b82f6;
    border-color: #3b82f6;
    transition: all 0.2s ease;

    &:hover {
      background-color: #2563eb;
      border-color: #2563eb;
      transform: translateY(-1px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(0);
      box-shadow: none;
    }
  }
}

/* Add transition for smooth element appearance */
.footer-container {
  transition: all 0.3s ease;
}

/* Animation for new elements */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.footer-element {
  animation: fadeIn 0.3s ease;
}

// Add styles for CKEditor content
:deep(.ck-content) {
  .image {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    display: block !important;
    
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
      display: block !important;
    }
  }
}
</style>